import React, { useContext, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
const PrivateRoute = ({ children, ...props }) => {
  const { isAuthenticated, setOldPath } = useContext(AuthContext);
  let history = useHistory();

  //   return isAuthenticated ? (
  //     <Route {...props}>{children}</Route>
  //   ) : (
  //     <Redirect to={{ pathname: "/login" }} />
  //     // <Route {...props}>{children}</Route>
  //   );
  console.log(isAuthenticated);
  if (isAuthenticated) {
    return <Route {...props}>{children}</Route>;
  } else {
    setOldPath(history.location.pathname);
    return <Redirect to={{ pathname: "/login" }} />;
  }
};

export default PrivateRoute;
