// authProvider.js
import { MsalAuthProvider, LoginType } from "react-aad-msal";
//tenant id: e485c427-0599-4dbd-86d9-add2e5942a49
//client id : 93761509-eab4-410d-bd95-7f4ceaa20a60
const config = {
  auth: {
    authority:
      "https://login.microsoftonline.com/e485c427-0599-4dbd-86d9-add2e5942a49",
    clientId: "93761509-eab4-410d-bd95-7f4ceaa20a60",
    // redirectUri: "localhost"
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
const options = {
  loginType: LoginType.Popup,
};

const authenticationParameters = {
  scopes: ["openid"],
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
