import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import Calculator from "./Components/Calculator";
import Login from "./Container/Login/Login";
import { AuthContext } from "./Auth/AuthContext";
import PrivateRoute from "./Auth/PrivateRoute";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";

function App() {
  const { authProviderLocal, isAuthenticated } = useContext(AuthContext);
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/login">
            <Login login={authProviderLocal} authCheck={isAuthenticated} />
          </Route>
          <PrivateRoute path="/">
            <>
              <h2>Trench Calculator</h2>
              <Calculator />
            </>
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
