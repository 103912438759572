import React, { useEffect, useState } from "react";
import axios from "axios";
const Calculator = () => {
  const [didClick, setDidClick] = useState(false);
  const [dropDowns, setDropDowns] = useState({});
  const [apiBody, setAPIBody] = useState({
    MICRO_NUM: 0,
    TRENCH_LENGTH: 0,
    MICRO_TYPE: "IQ6",
    SYS_VOLT: 208,
  });
  const [oldAPI, setOldAPI] = useState({});
  const [apiResponse, setAPIResponse] = useState(null);

  const isAPIValid = (api) => {
    const { MICRO_NUM, TRENCH_LENGTH, MICRO_TYPE, SYS_VOLT } = api;
    if (
      !MICRO_NUM ||
      MICRO_NUM === "" ||
      !TRENCH_LENGTH ||
      TRENCH_LENGTH === "" ||
      !MICRO_TYPE ||
      MICRO_TYPE === "" ||
      !SYS_VOLT ||
      SYS_VOLT === ""
    ) {
      return false;
    } else {
      return true;
    }
  };
  const initialFetch = async () => {
    try {
      let dropdowns = await axios.get(
        `https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/trench_calculator/sales_trench_dropdowns`
      );
      console.log(dropdowns.data);
      setDropDowns(dropdowns.data);
    } catch (e) {
      alert("Error retrieving dropdowns");
    }
  };

  useEffect(() => {
    initialFetch();
  }, []);

  const fetchResults = async () => {
    if (isAPIValid(apiBody)) {
      try {
        let info = await axios.post(
          `https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/trench_calculator/sales_trench_sheet`,
          {
            ...apiBody,
            MICRO_NUM: +apiBody.MICRO_NUM,
            TRENCH_LENGTH: +apiBody.TRENCH_LENGTH,
            SYS_VOLT: +apiBody.SYS_VOLT,
          }
        );
        console.log(info.data);
        setDidClick(true);
        setOldAPI(apiBody);
        setAPIResponse(info.data);
      } catch (e) {
        console.log("error");
        console.log(e);
      }
    }
  };

  const changeHandler = (e) => {
    console.log(e.currentTarget.value);
    console.log(e.currentTarget.id);
    let update = { ...apiBody };
    update[e.currentTarget.id] = e.currentTarget.value;
    setAPIBody(update);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!didClick && (
        <button
          style={{
            background: "rgb(24,119,242",
            fontSize: "0.9375rem",
            fontWeight: "600",
            borderRadius: "7px",
            border: "none",
            outline: "none",
            padding: "0.5em",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px;",
            color: "white",
            marginBottom: "0.5em",
          }}
          onClick={fetchResults}
        >
          Submit
        </button>
      )}
      {oldAPI !== apiBody && Object.keys(oldAPI).length > 0 && (
        <button
          style={{
            background: "rgb(24,119,242",
            fontSize: "0.9375rem",
            fontWeight: "600",
            borderRadius: "7px",
            border: "none",
            outline: "none",
            padding: "0.5em",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px;",
            color: "white",
            marginBottom: "0.5em",
          }}
          onClick={fetchResults}
        >
          Update Values
        </button>
      )}
      <div
        style={{
          width: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <table
          style={{
            borderCollapse: "collapse",
            boxShadow: " rgba(0, 0, 0, 0.15) 0px 5px 5px",
            borderRadius: "10px",
          }}
        >
          <thead style={{ background: "#336699", color: "white" }}>
            <tr>
              <th></th>
              <th>TYPE OF MICROS</th>
              <th># OF MICROS</th>
              <th>TOTAL SYSVOLT</th>
              <th>TOTAL SYS AMPS</th>
              <th>FUSE SIZE</th>
              <th>WIRE SIZE</th>
              <th>MAX DIST @240V</th>
              <th>MAX DIST @208V</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ borderRight: "solid thin" }}></td>
              <td>
                {Object.keys(dropDowns).length > 0 && (
                  <select id={"MICRO_TYPE"} onChange={changeHandler}>
                    {dropDowns.micro_types.map((i) => {
                      return (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                )}
              </td>
              <td>
                <input
                  type="number"
                  value={apiBody.MICRO_NUM}
                  id={"MICRO_NUM"}
                  placeholder="micro number"
                  onChange={changeHandler}
                />
              </td>
              <td>
                {Object.keys(dropDowns).length > 0 && (
                  <select id={"SYS_VOLT"} onChange={changeHandler}>
                    {dropDowns.sys_volt.map((i) => {
                      return (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                )}
              </td>
              <td>{apiResponse && apiResponse.total_sys_amps.value}</td>
              <td>{apiResponse && apiResponse.fuse_size.value}</td>
              <td>{apiResponse && apiResponse.wire_size.value}</td>
              <td>{apiResponse && apiResponse.max_dist_240.value}</td>
              <td>{apiResponse && apiResponse.max_dist_208.value}</td>
            </tr>
            <tr>
              <td style={{ borderRight: "solid thin" }}>
                Max Micros per 20A ckt
              </td>
              <td>{apiResponse && apiResponse.max_micros_per_20a_ckt.value}</td>
              <td></td>
              <td></td>
              <td>EGC</td>
              <td>{apiResponse && apiResponse.egc.value}</td>
              <td></td>
              <td>Trench Length</td>
              <td>
                <input
                  type="number"
                  value={apiBody.TRENCH_LENGTH}
                  id={"TRENCH_LENGTH"}
                  onChange={changeHandler}
                />
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: "solid thin" }}>
                # of 20A branch ckts
              </td>
              <td>{apiResponse && apiResponse.num_20a_branch_ckts.value}</td>
              <td></td>
              <td></td>
              <td>GEC</td>
              <td>{apiResponse && apiResponse.gec.value}</td>
              <td></td>
              <td>Cost Per foot</td>
              <td>
                {apiResponse && apiResponse.cost_per_ft.value
                  ? `$${apiResponse.cost_per_ft.value.toFixed(2)}`
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: "solid thin" }}>Single Phase</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Trench Total Cost</td>
              <td>
                {apiResponse && apiResponse.trench_total_cost.value
                  ? `$${apiResponse.trench_total_cost.value.toFixed(2)}`
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: "solid thin" }}>Trench Dist</td>
              <td>{apiBody.TRENCH_LENGTH}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td style={{ borderRight: "solid thin" }}>Wire Size (3 wires)</td>
              <td>
                {apiResponse && apiResponse.wire_size_three_wires.value
                  ? apiResponse.wire_size_three_wires.value
                  : "N/A"}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td style={{ borderRight: "solid thin" }}>EGC upsized</td>
              <td>
                {apiResponse && apiResponse.egc_upsized.value
                  ? apiResponse.egc_upsized.value
                  : "N/A"}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td
                style={{ borderRight: "solid thin", borderTop: "solid thin" }}
              >
                Conduit size (sched40) PVC
              </td>
              <td style={{ borderTop: "solid thin" }}>
                {apiResponse && apiResponse.conduit_size_sched_40.value
                  ? apiResponse.conduit_size_sched_40.value
                  : "N/A"}
              </td>
              <td style={{ borderTop: "solid thin" }}></td>
              <td style={{ borderTop: "solid thin" }}>Cost per foot</td>
              <td style={{ borderTop: "solid thin" }}>
                {apiResponse && apiResponse.larger_cost_per_foot.value
                  ? `$${apiResponse.larger_cost_per_foot.value.toFixed(2)}`
                  : "N/A"}
              </td>
              <td style={{ borderTop: "solid thin" }}></td>
              <td style={{ borderTop: "solid thin" }}></td>
              <td style={{ borderTop: "solid thin" }}>Total Cost of Trench</td>
              <td style={{ borderTop: "solid thin" }}>
                {apiResponse && apiResponse.larger_trench_total_cost.value
                  ? `$${apiResponse.larger_trench_total_cost.value.toFixed(2)}`
                  : "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calculator;
